<template>
	<div  class="fadeInRight charge_log ">
			<div class="title-box clearfix">
				 <span class="m-title">查看详情</span>
			<el-form :inline="true" class="right themed-form search-form" style="height: auto;line-height: 40px;">
				<el-form-item>
					<el-button type="primary" @click="exportExcel" icon="el-icon-caret-left" class="themed-button">批量导出</el-button>
				</el-form-item>
				<iframe name="hidden_down_iframe" id="hidden_down_iframe" style="display: none;"  src=""></iframe>
			</el-form>
		</div>
		<div class="content-box">
			<el-table :data="tabledata" stripe border style="width: 100%;" ref="table" :max-height="maxTableHeight">
				<el-table-column prop="iccidMark" label="ICCID">
				</el-table-column>
				<el-table-column prop="phone" label="接入号">
				</el-table-column>
				<el-table-column prop="todayUsedFlow" label="当日使用量">
				</el-table-column>
				<el-table-column  label="查询时间">
					<template slot-scope="scope">
						<span >{{getQueryTime(scope.row)}}</span>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination :current-page.sync="page" :page-sizes="[10,15,25,100]" :page-size="pageSize" style="text-align: left;margin-top: 20px;"
			 background layout="total,sizes,prev, pager, next,jumper" :total="total" @size-change="handleSizeChange"
			 @current-change="onPageChange">
			</el-pagination>
		</div>
		
	</div>
	
</template>

<script>
	import onresize from "@/mixins/resize.js"
	import loading from '@/utils/Loading.js'
	import {
	packgUsageDetail,
	DetailExport
	} from "@/api/staticsManagement.js"
	
	export default {
		name:'PUSdetail',
		mixins: [onresize],
		data(){
			return {
				tabledata:[],
				page:1,
			  pageSize:15,
				total:0,
				query:{
					accountId:this.$route.query.accountId,
					setMealId:this.$route.query.setMealId,
					synDate:this.$route.query.synDate,
					cardStatus:this.$route.query.type == 1?"2":"1",
				},
				
				
			}
		},
		created(){
			//console.log(this.query)
			let params = this.query
			  
			this.getDetailList(params)
				
		},
		methods:{
			/* exportExcel(){ */
				/* ../../flowmonitor/exportFlow.do?accountId=105CCB4B828043D289C20FE761FB0EAC&setMealId=53983D78304A410FBCF8CC905FC1946F&synDate=2021-01-08&cardStatus=1 */
			/* 	packageSaleExport(this.query).then(()=>{
					let accountId =  this.$route.accountId == undefined? "":this.$route.accountId
					let setMealId = this.$route.setMealId == undefined? "":this.$route.setMealId
					let synDate =  this.$route.synDate== undefined? "":this.$route.synDate
					let cardStatus =  this.$route.type == undefined? "":this.$route.type
					let url = "https://iottest.sunshineiot.net/traffic-web/flowmonitor/exportFlow.do?"+"accountId="+accountId+"&setMealId="+setMealId+"&synDate="+synDate+"&cardStatus="+cardStatus;
					url = encodeURI(encodeURI(url));
					document.getElementById("hidden_down_iframe").src= url;
				})
			}, */
			exportExcel(){
							loading.show()
							let params  = {
								accountId:this.$route.query.accountId == undefined? "":this.$route.query.accountId,
								setMealId:this.$route.query.setMealId == undefined? "":this.$route.query.setMealId,
								synDate:this.$route.query.synDate== undefined? "":this.$route.query.synDate,
								//cardStatus:this.$route.query.type == undefined? "":this.$route.query.type
								cardStatus:this.$route.query.type == 1?"2":"1",
							}
							DetailExport(params).then(res=>{
								if (res.system_result_key == '0') {
									this.$alert('导出成功，请到导出记录中下载', '提示', {
									    confirmButtonText:'确定',
									}).then(()=>{
										this.$router.push('/tob/i_export_list')
									});
								}else{
									this.$message({
									    showClose: true,
									    message: res.system_result_message_key,
									    type: 'error'
									});
								}
							})
						},
			getQueryTime(rowData){
				if(!rowData.createDate || rowData.createDate==="") {
				    return ""
				}
				var d = new Date(rowData.createDate);
							
				return d.getFullYear()+"-"+(d.getMonth()+1)+"-"+d.getDate()+" "+d.getHours()+":"+d.getMinutes()+":"+d.getSeconds();
			},
			handleSizeChange(size){
				this.pageSize = size
				let params = 
				{
					pageSize:this.pageSize,
					pageNo:this.page
				}
				Object.assign(params,this.query)
				this.getDetailList(params)
			},
			onPageChange(page){
				this.page = page
				let params = 
				{
					pageSize:this.pageSize,
					pageNo:this.page
				}
				Object.assign(params,this.query)
				this.getDetailList(params)
			},
			getDetailList(params){
				packgUsageDetail(params).then(res=>{
					this.tabledata = res.rows
					this.page = res.pageNo
					this.pageSize = res.pageSize
					this.total = res.total
				})
			},
		}
	}
	
</script>

<style>
</style>
